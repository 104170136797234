.App {
  background: #1E2835;
}

.overflowY {
overflow-Y: hidden;
}

.height {
  min-height: 100vh;
  background: linear-gradient(270deg, #C30724 0%, #1E2835 101.25%);
}

.button {
    font-size: 15px;
    font-weight: bold;
    background: transparent;
    border: none;
    border-radius: 32px;
    padding: 12px 30px;
    transition: all .15s ease-in-out;
    position: relative;
}

.primary-button {
    background-color: #C40707;
    border: 2px solid #C40707;
    color: #fff;
}

.button:hover {
    text-decoration: none;
    font-size: 15px;
    color: #C40707;
    background-color: transparent;
    border: 2px solid #C40707;
}



.containerVideo {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.video {
  position: absolute;
  width: auto;
  height: 100%;
  top: 0;
  left: 0;
}

.nyugo_title {
font-style: normal;
font-weight: normal;
font-size: 6vw;
letter-spacing: 1.35px;
color: #FFFFFF;
}

.under_construction {
font-style: normal;
font-weight: 300;
font-size: 4vw;
color: #FFFFFF;
}

.contact {
font-style: normal;
font-weight: 300;
font-size: 3vw;
color: #FFFFFF;
}

.contact_info {
font-style: normal;
font-weight: normal;
font-size: 2vw;
color: #FFFFFF;
}

* {
  box-sizing: border-box;
}

.body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  background-color: #f2f2f2;
}

.info {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 14px;
  a {
    color: inherit;
  }

  .notice {
    margin-top: 1rem;
    font-weight: 100;
  }

  @media screen and (min-width: 980px) {
    font-size: 18px;
    .notice {
      display: none;
    }
  }
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background-color: #f2f2f2;
}

.hero-body {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem;
  @media screen and (min-width: 980px) {
    padding: 8rem 1.5rem;
  }
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  background-color: #f2f2f2;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex: 1 1 auto;
  padding: 10px;
  width: 100%;
  @media screen and (min-width: 980px) {
    width: 33.33333333333333%;
  }
}

.card {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
}

.card-title {
  font-size: 22px;
  font-weight: 600;
}

.card-body {
  margin-top: 27px;
  margin-bottom: 27px;
  line-height: 1.5;
  font-size: 16px;
  @media screen and (min-width: 576px) {
    font-size: 18px;
  }
}

.image-container {
  margin-top: auto;
  padding-top: 27px;
  padding-bottom: 18px;
  @media screen and (min-width: 576px) {
    padding-bottom: 30px;
  }

  img {
    width: 100%;
  }
}

.image-inner-container {
  width: 100%;
  margin: 0 auto;
  max-width: 250px;
}

.ratio {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
}

.ratio-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.nyugo_under_title {
font-style: normal;
font-weight: bold;
font-size: 3rem;
}

@media screen and (max-width: 375px) {
  .nyugo_under_title {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 414px) {
  .nyugo_under_title {
    font-size: 2rem;
  }
}

/*SURFACE DUO*/
@media screen and (max-width: 540px) {
  .nyugo_under_title {
    font-size: 2rem;
  }
}


/*style={{position: 'absolute', top: '40vh', left: '5vw', width: '40vw'}}*/
.position_h1 {
  position: absolute;
  top: 45vh;
  left: 5vw;
}

@media screen and (max-width: 1600px) {
  .position_h1 {
    top: 45vh;
    left: 0;
  }
}

@media screen and (max-width: 1350px) {
  .position_h1 {
    top: 20vh;
    left: 24vw;
    width: 50vw;
  }
}

@media screen and (max-width: 1024px) {
  .position_h1 {
    top: 20vh;
    left: 23vw;
    width: 50vw;
  }
}

/*SURFACE DUO*/
@media screen and (max-width: 540px) {
  .position_h1 {
    top: 20vh;
    left: 10vw;
    width: 80%;
  }
}

@media screen and (max-width: 414px) {
  .position_h1 {
    top: 20vh;
    left: 0;
    width: 100%;
  }
}


@media screen and (max-width: 375px) {
  .position_h1 {
    top: 20vh;
    left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .position_h1 {
    top: 23vh;
  }
}

.centerVideo {
  position: relative;
  top: 0;
  left: 0;
  margin: auto;

 border-radius: 15px;
 border-color: transparent;
 border-width: 3px;

 border-style: solid;
 overflow: hidden;
}

.videoApp {
  position: absolute;
  width: auto;
  height: auto;
  top: 25vh;
  left: 50vw;
  right: 2vw;
}

.centerVideo2 {
  position: absolute;
  top: 0;
  left: 0;
}


.videoApp2 {
  position: relative;
    padding-top: 56.25%;

}

@media screen and (max-width: 1600px) {
  .videoApp {
    top: 25vh;
    left: 50vw;
    right: 2vw;
  }
}

@media screen and (max-width: 1350px) {
  .videoApp {
    top: 42vh;
    left: 25vw;
    right: 25vw;
  }
}

/*IPAD PRO*/
@media screen and (max-width: 1024px) {
  .videoApp {
    top: 46vh;
    right: 15vw;
    left: 15vw;
  }
}


/*IPAD*/
@media screen and (max-width: 768px) {
  .videoApp {
    top: 48vh;
    right: 15vw;
    left: 15vw;
  }
}


/*SURFACE DUO*/
@media screen and (max-width: 540px) {
  .videoApp {
    top: 45vh;
    left: 5vw;
    right: 5vw;
  }
}

/*IPHONE 6/7/8 PLUS*/
@media screen and (max-width: 414px) {
  .videoApp {
    top: 45vh;
    right: 0vw;
    left: 0vw;
  }
}


/*IPHONE X*/
@media screen and (max-width: 375px) {
  .videoApp {
    top: 45vh;
    right: 0vw;
    left: 0vw;
  }
}

/*GALAXY S5*/
@media screen and (max-width: 360px) {
  .videoApp {
    top: 45vh;
    right: 5vw;
    left: 5vw;
  }
}

/*IPHONE 5/SE*/
@media screen and (max-width: 320px) {
  .videoApp {
    top: 45vh;
    right: 4vw;
    left: 5vw;
  }
}

.appStore {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 414px) {
  .appStore {
    justify-content: center;
  }
}

.playStore {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 414px) {
  .playStore {
    justify-content: center;
  }
}


.shadow {
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    3px 3px 5px 6px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow:         3px 3px 5px 6px #ccc;
}
